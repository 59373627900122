import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import Select from "react-select";
import { toast } from "react-toastify";
import { ApiUrl } from "../ApiUrl";
const FilterBar = ({
  isDarkMode,
  checkedFields,
  setCheckedFields,
  allfields,
  textOptions,
  textOptions2,
  filterOption,
  setFilterOption,
  dealerOptions,
  repOptions2,
  managers,
  packageOptions,
  animatorOptions,
  getFilteredEvents,
  weekOptions,
  setFiltersData,
  filtersData,
  agentOptions
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");

  const button = document.getElementById("filterClose");
  const addFilter = async (field) => {
    // setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/add-fillter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(field),
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
    }
  };
  const [weekRange, setWeekRange] = useState({ range1: "", range2: "" });
  const eventsFilterData = JSON.parse(localStorage.getItem("eventsFilterData"));

  return (
    <div
      className={`offcanvas offcanvas-end offcanvas-sm h-100 ${
        isDarkMode ? "bg-dark" : "bg-body-tertiary"
      } `}
      tabindex="-1"
      id="filtersidebar"
      data-bs-backdrop="true"
      aria-bs-labelledby="offcanvasmainLabel"
      // style={{minHeight:"100vh"}}
    >
      <div className="flex-column offcanvas-body" style={{ height: "100vh" }}>
        <div
          className="d-flex align-items-center justify-content-between pt-4 px-4"
          style={{
            borderBottom: "1px solid #dee2e6",
          }}
        >
          <h3>{translations.commonWords.search[language]} </h3>
          <button
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="filterClose"
            style={{
              background: "transparent",
              color: isDarkMode ? "white" : "black",
              border: "none",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            X
          </button>
        </div>
        <div
          className=""
          style={{ maxHeight: "calc(100vh - 147px", overflowY: "auto" }}
        >
          <ul
            className={`nav flex-column gap-2 px-4 ${
              checkedFields && checkedFields.length > 0 ? "mt-4" : "mt-4"
            } pb-4 `}
          >
            {allfields &&
              allfields.map((data1, index) => (
                <li className="py-1" key={index}>
                  <div className="d-flex gap-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={checkedFields.some(
                        (field) => field.name === data1.name
                      )}
                      id={data1.name}
                      name={data1.name}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // Check if the type is not "date"
                          if (data1.type === "date_range") {
                            if (
                              !checkedFields.some(
                                (field) => field.name === data1.name
                              )
                            ) {
                              setCheckedFields([
                                ...checkedFields,
                                {
                                  name: data1.name,
                                  content: data1.content,
                                  type: data1.type,
                                  value: "",
                                  filterType: data1.filterType,
                                  component: data1.component,
                                },
                              ]);
                              // localStorage.setItem(
                              //   "eventsFilterData",
                              //   JSON.stringify([
                              //     ...checkedFields,
                              //     {
                              //       name: data1.name,
                              //       content: data1.content,
                              //       type: data1.type,
                              //       value: "",
                              //       filterType: data1.filterType,
                              //       component: data1.component,
                              //     },
                              //   ])
                              // );
                              // addFilter({ [data1.name]: "1" });
                            }
                            return;
                          }

                          if (data1.type !== "date") {
                            if (
                              !checkedFields.some(
                                (field) => field.name === data1.name
                              )
                            ) {
                              setCheckedFields([
                                ...checkedFields,
                                {
                                  name: data1.name,
                                  content: data1.content,
                                  type: data1.type,
                                  value: "",
                                  filterType: "like",
                                },
                              ]);
                              // localStorage.setItem(
                              //   "eventsFilterData",
                              //   JSON.stringify([
                              //     ...checkedFields,
                              //     {
                              //       name: data1.name,
                              //       content: data1.content,
                              //       type: data1.type,
                              //       value: "",
                              //       filterType: data1.filterType,
                              //     },
                              //   ])
                              // );
                              // addFilter({ [data1.name]: "1" });
                            }
                            return;
                          }

                          // Handle "date" type
                          if (data1.name === "end_date") {
                            const startDateField = checkedFields.find(
                              (field) => field.name === "start_date"
                            );

                            if (startDateField && startDateField.value) {
                              if (
                                !checkedFields.some(
                                  (field) => field.name === "end_date"
                                )
                              ) {
                                setCheckedFields([
                                  ...checkedFields,
                                  {
                                    name: data1.name,
                                    content: data1.content,
                                    type: data1.type,
                                    value: "",
                                    filterType: "",
                                  },
                                ]);
                                // localStorage.setItem(
                                //   "eventsFilterData",
                                //   JSON.stringify([
                                //     ...checkedFields,
                                //     {
                                //       name: data1.name,
                                //       content: data1.content,
                                //       type: data1.type,
                                //       value: "",
                                //       filterType: data1.filterType,
                                //     },
                                //   ])
                                // );
                                addFilter({ [data1.name]: "1" });
                              }
                            } else {
                              // Show error if start date is missing
                              toast.error(
                                translations.commonFields.queryFilters
                                  .endDateError[language]
                              );
                            }
                          } else {
                            // Handle start_date
                            if (
                              !checkedFields.some(
                                (field) => field.name === "start_date"
                              )
                            ) {
                              setCheckedFields([
                                ...checkedFields,
                                {
                                  name: data1.name,
                                  content: data1.content,
                                  type: data1.type,
                                  value: "",
                                  filterType: "",
                                },
                              ]);
                              // localStorage.setItem(
                              //   "eventsFilterData",
                              //   JSON.stringify([
                              //     ...checkedFields,
                              //     {
                              //       name: data1.name,
                              //       content: data1.content,
                              //       type: data1.type,
                              //       value: "",
                              //       filterType: data1.filterType,
                              //     },
                              //   ])
                              // );
                              // addFilter({ [data1.name]: "1" });
                            }
                          }
                        } else {
                          let updatedCheckedFields = checkedFields.filter(
                            (field) => field.name !== data1.name
                          );
                          if (data1.name === "date_range") {
                            updatedCheckedFields = updatedCheckedFields.filter(
                              (field) =>
                                field.name !== "start_date" &&
                                field.name !== "end_date"
                            );
                          }
                          // Remove the object with the matching name
                          setCheckedFields(updatedCheckedFields);

                          // localStorage.setItem(
                          //   "eventsFilterData",
                          //   JSON.stringify(
                          //     checkedFields.filter(
                          //       (field) => field.name !== data1.name
                          //     )
                          //   )
                          // );

                          const keysToRemove =
                            data1.type === "date_range"
                              ? ["start_date", "end_date"]
                              : [
                                  data1.type === "dropdown"
                                    ? data1.name + "_id"
                                    : data1.name,
                                ];
                          const updatedFiltersData = Object.fromEntries(
                            Object.entries(filtersData).filter(
                              ([key]) => !keysToRemove.includes(key)
                            )
                          );

                          setFiltersData(updatedFiltersData);

                          getFilteredEvents(
                            updatedFiltersData,
                            updatedCheckedFields
                          );
                        }
                      }}
                    />

                    <p className="fw-bold">{data1.content}</p>
                  </div>

                  <div>
                    {/* Conditionally render the field based on checkedFields and its type */}
                    {checkedFields &&
                      checkedFields.length > 0 &&
                      checkedFields.some(
                        (field) => field.name === data1.name
                      ) && (
                        <>
                          {checkedFields &&
                            checkedFields.length > 0 &&
                            checkedFields.map((data, index) => (
                              <div key={index}>
                                {/* Render field based on type */}
                                {data.type === "text" &&
                                  data.name === data1.name && (
                                    <div className="my-2">
                                      <Select
                                        name={data.name}
                                        className="w-100 selectpicker"
                                        aria-labelledby="dealership-name-quick"
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        value={(() => {
                                          const matchingFilter =
                                            checkedFields?.find(
                                              (field) =>
                                                field.name === data.name
                                            )?.filterType;

                                          return (
                                            (data.name === "week"
                                              ? weekOptions
                                              : textOptions
                                            ).find(
                                              (option) =>
                                                option.value === matchingFilter
                                            ) || null
                                          );
                                        })()}
                                        isSearchable={false}
                                        options={
                                          data.name === "week"
                                            ? weekOptions
                                            : textOptions
                                        }
                                        onChange={(selectedOption) => {
                                          // ✅ Update filterType in checkedFields while preserving other values
                                          const updatedCheckedFields =
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    value: "",
                                                    filterType:
                                                      selectedOption.value, // Update filterType
                                                  }
                                                : field
                                            );

                                          setCheckedFields(
                                            updatedCheckedFields
                                          );

                                          if (data.value) {
                                            // ✅ Preserve the existing value correctly
                                            const Data = {
                                              [data.name]: [
                                                "",
                                                selectedOption.value,
                                              ], // Preserve data.value
                                            };

                                            // ✅ Ensure only non-empty fields are considered
                                            const filteredFields =
                                              updatedCheckedFields.filter(
                                                (field) =>
                                                  field.value &&
                                                  field.value
                                                    .toString()
                                                    .trim() !== ""
                                              );

                                            // ✅ Convert checkedFields to key-value pairs while preserving strings
                                            const filteredData =
                                              filteredFields.reduce(
                                                (acc, field) => {
                                                  const key =
                                                    field.type === "dropdown"
                                                      ? `${field.name}_id`
                                                      : field.name;

                                                  // Prevent duplicate entries in the array
                                                  if (
                                                    Array.isArray(field.value)
                                                  ) {
                                                    acc[key] = [
                                                      ...new Set([
                                                        ...field.value,
                                                      ]),
                                                    ]; // Remove duplicates
                                                  } else {
                                                    acc[key] =
                                                      field.type === "text"
                                                        ? [
                                                            field.value,
                                                            field.filterType,
                                                          ]
                                                        : [field.value];
                                                  }

                                                  return acc;
                                                },
                                                {}
                                              );

                                            // ✅ Merge filtered data while preserving previous values
                                            const mergedData = {
                                              ...filtersData,
                                              ...filteredData,
                                              ...Data, // Ensure latest values overwrite old ones
                                            };

                                            if (filterOption) {
                                              mergedData.type = filterOption;
                                            }

                                            setFiltersData(mergedData);
                                            console.log(
                                              "Updated Filters Data:",
                                              mergedData
                                            );
                                          }
                                        }}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            width: "100%",
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "white",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none",
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem",
                                            width: "100%",
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0",
                                            width: "100%",
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333",
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                      {data.filterType === "between" ? (
                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                          <input
                                            type="text"
                                            name={data.name + "1"}
                                            value={
                                              checkedFields?.find(
                                                (field) =>
                                                  field.name === data.name
                                              )?.value[0]
                                            }
                                            className="form-control mt-2"
                                            onChange={(e) => {
                                              const { value } = e.target;

                                              // ✅ Update weekRange correctly
                                              setWeekRange((prevRange) => {
                                                const updatedRange = {
                                                  ...prevRange,
                                                  range1: value,
                                                };

                                                // ✅ Ensure checkedFields updates correctly
                                                const updatedCheckedFields =
                                                  checkedFields.map((field) =>
                                                    field.name === data.name
                                                      ? {
                                                          ...field,
                                                          value: [
                                                            value, // Update range1
                                                            field.value?.[1] ||
                                                              "", // Preserve range2
                                                          ],
                                                        }
                                                      : field
                                                  );

                                                setCheckedFields(
                                                  updatedCheckedFields
                                                );

                                                if (data.filterType) {
                                                  // ✅ Use updated weekRange correctly
                                                  const Data = {
                                                    [data.name]: [
                                                      value,
                                                      data.filterType,
                                                      prevRange.range2,
                                                    ], // Using prevRange to ensure latest state
                                                  };

                                                  // ✅ Filter only non-empty values correctly
                                                  const filteredFields =
                                                    updatedCheckedFields.filter(
                                                      (field) =>
                                                        field.value &&
                                                        field.value
                                                          .toString()
                                                          .trim() !== ""
                                                    );

                                                  // ✅ Convert checkedFields to key-value pairs while preserving strings
                                                  const filteredData =
                                                    filteredFields.reduce(
                                                      (acc, field) => {
                                                        const key =
                                                          field.type ===
                                                          "dropdown"
                                                            ? `${field.name}_id`
                                                            : field.name;

                                                        // Prevent duplicate entries in the array
                                                        if (
                                                          Array.isArray(
                                                            field.value
                                                          )
                                                        ) {
                                                          acc[key] = [
                                                            ...new Set([
                                                              ...field.value,
                                                            ]),
                                                          ]; // Remove duplicates
                                                        } else {
                                                          acc[key] =
                                                            field.type ===
                                                            "text"
                                                              ? [
                                                                  field.value,
                                                                  field.filterType,
                                                                ]
                                                              : [field.value];
                                                        }

                                                        return acc;
                                                      },
                                                      {}
                                                    );

                                                  // ✅ Merge new data while preserving old filters
                                                  const mergedData = {
                                                    ...filtersData,
                                                    ...filteredData,
                                                    ...Data, // Ensure latest values overwrite old ones
                                                  };

                                                  if (filterOption) {
                                                    mergedData.type =
                                                      filterOption;
                                                  }

                                                  setFiltersData(mergedData);
                                                  console.log(
                                                    "Updated Filters Data:",
                                                    mergedData
                                                  );
                                                }

                                                return updatedRange; // Return updated state for immediate access
                                              });
                                            }}
                                            style={{
                                              backgroundColor: isDarkMode
                                                ? "transparent"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#000000",
                                              border: isDarkMode
                                                ? "1px solid #495057"
                                                : "1px solid #dee2e6",
                                            }}
                                          />
                                          <input
                                            type="text"
                                            name={data.name + "2"}
                                            className="form-control mt-2"
                                            value={
                                              checkedFields?.find(
                                                (field) =>
                                                  field.name === data.name
                                              )?.value[1]
                                            }
                                            onChange={(e) => {
                                              const { value } = e.target;

                                              // Update weekRange with the new value (either range1 or range2)
                                              // ✅ Update weekRange with the new value (range2)
                                              setWeekRange((prevRange) => {
                                                const updatedRange = {
                                                  ...prevRange,
                                                  range2: value,
                                                };

                                                // ✅ Ensure checkedFields is updated properly
                                                const updatedCheckedFields =
                                                  checkedFields.map((field) =>
                                                    field.name === data.name
                                                      ? {
                                                          ...field,
                                                          value: [
                                                            prevRange.range1 ||
                                                              "", // Preserve range1
                                                            value, // Set range2
                                                          ],
                                                        }
                                                      : field
                                                  );

                                                setCheckedFields(
                                                  updatedCheckedFields
                                                );

                                                if (data.filterType) {
                                                  // ✅ Use updated range values
                                                  const Data = {
                                                    [data.name]: [
                                                      prevRange.range1,
                                                      data.filterType,
                                                      value,
                                                    ],
                                                  };

                                                  // ✅ Filter only non-empty values
                                                  const filteredFields =
                                                    updatedCheckedFields.filter(
                                                      (field) =>
                                                        field.value &&
                                                        field.value
                                                          .toString()
                                                          .trim() !== "" // Works for both arrays & strings
                                                    );

                                                  // ✅ Convert checkedFields to key-value pairs while preserving strings
                                                  const filteredData =
                                                    filteredFields.reduce(
                                                      (acc, field) => {
                                                        const key =
                                                          field.type ===
                                                          "dropdown"
                                                            ? `${field.name}_id`
                                                            : field.name;

                                                        // Prevent duplicate entries in the array
                                                        if (
                                                          Array.isArray(
                                                            field.value
                                                          )
                                                        ) {
                                                          acc[key] = [
                                                            ...new Set([
                                                              ...field.value,
                                                            ]),
                                                          ]; // Remove duplicates
                                                        } else {
                                                          acc[key] =
                                                            field.type ===
                                                            "text"
                                                              ? [
                                                                  field.value,
                                                                  field.filterType,
                                                                ]
                                                              : [field.value];
                                                        }

                                                        return acc;
                                                      },
                                                      {}
                                                    );

                                                  // ✅ Merge new data while preserving old filters
                                                  const mergedData = {
                                                    ...filtersData,
                                                    ...filteredData,
                                                    ...Data, // Ensure latest values overwrite old ones
                                                  };

                                                  if (filterOption) {
                                                    mergedData.type =
                                                      filterOption;
                                                  }

                                                  setFiltersData(mergedData);
                                                  console.log(
                                                    "Updated Filters Data:",
                                                    mergedData
                                                  );
                                                }

                                                return updatedRange; // Return updated state for immediate access
                                              });
                                            }}
                                            style={{
                                              backgroundColor: isDarkMode
                                                ? "transparent"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#000000",
                                              border: isDarkMode
                                                ? "1px solid #495057"
                                                : "1px solid #dee2e6",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <input
                                          type="text"
                                          name={data.name}
                                          className="form-control mt-2"
                                          value={
                                            checkedFields?.find(
                                              (field) =>
                                                field.name === data.name
                                            )?.value
                                          }
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            // ✅ Ensure previous checkedFields are updated properly
                                            const updatedCheckedFields =
                                              checkedFields.map((field) =>
                                                field.name === data.name
                                                  ? {
                                                      ...field,
                                                      value: newValue,
                                                    }
                                                  : field
                                              );
                                            // Update the value in the object with the input text
                                            setCheckedFields(
                                              updatedCheckedFields
                                            );

                                            if (data.filterType) {
                                              // const Data = {
                                              //   [data.name]: [
                                              //     e.target.value,
                                              //     data.filterType,
                                              //   ],
                                              // };
                                              // ✅ Get updated filtered fields
                                              const filteredFields =
                                                updatedCheckedFields.filter(
                                                  (field) =>
                                                    field.value &&
                                                    field.value
                                                      .toString()
                                                      .trim() !== ""
                                                );

                                              // ✅ Convert to key-value pairs (Preserve string values)
                                              // ✅ Convert checkedFields to key-value pairs while preserving strings
                                              const filteredData =
                                                filteredFields.reduce(
                                                  (acc, field) => {
                                                    const key =
                                                      field.type === "dropdown"
                                                        ? `${field.name}_id`
                                                        : field.name;

                                                    // Prevent duplicate entries in the array
                                                    if (
                                                      Array.isArray(field.value)
                                                    ) {
                                                      acc[key] = [
                                                        ...new Set([
                                                          ...field.value,
                                                        ]),
                                                      ]; // Remove duplicates
                                                    } else {
                                                      acc[key] =
                                                        field.type === "text"
                                                          ? [
                                                              field.value,
                                                              field.filterType,
                                                            ]
                                                          : [field.value];
                                                    }

                                                    return acc;
                                                  },
                                                  {}
                                                );

                                              // ✅ Merge old filtersData while updating the new value
                                              const mergedData = {
                                                ...filtersData, // Preserve existing filters
                                                ...filteredData,
                                                [data.name]: [
                                                  newValue,
                                                  data.filterType,
                                                ], // Update only the current field
                                              };

                                              if (filterOption) {
                                                mergedData.type = filterOption;
                                              }

                                              setFiltersData(mergedData);
                                            }
                                          }}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}

                                {data.type === "date" &&
                                  data.name === data1.name && (
                                    <div className="mt-3">
                                      <input
                                        type="date"
                                        name={data.name}
                                        className={`form-control ${
                                          isDarkMode ? "custom-date" : ""
                                        }`}
                                        value={
                                          checkedFields?.find(
                                            (field) => field.name === data.name
                                          )?.value
                                        }
                                        onChange={(e) => {
                                          // Update the value in the object with the input text
                                          setCheckedFields(
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    value: e.target.value,
                                                  }
                                                : field
                                            )
                                          );

                                          const Data = {
                                            [data.name]: [e.target.value],
                                          };
                                          const filteredFields =
                                            checkedFields.filter(
                                              (field) =>
                                                field?.value !== "" ||
                                                field?.value.length > 0
                                            );

                                          // Create a new object from the filtered fields
                                          // ✅ Convert checkedFields to key-value pairs while preserving strings
                                          const filteredData =
                                            filteredFields.reduce(
                                              (acc, field) => {
                                                const key =
                                                  field.type === "dropdown"
                                                    ? `${field.name}_id`
                                                    : field.name;

                                                // Prevent duplicate entries in the array
                                                if (
                                                  Array.isArray(field.value)
                                                ) {
                                                  acc[key] = [
                                                    ...new Set([
                                                      ...field.value,
                                                    ]),
                                                  ]; // Remove duplicates
                                                } else {
                                                  acc[key] =
                                                    field.type === "text"
                                                      ? [
                                                          field.value,
                                                          field.filterType,
                                                        ]
                                                      : [field.value];
                                                }

                                                return acc;
                                              },
                                              {}
                                            );

                                          // Merge the filteredData object with the initial Data object
                                          const mergedData = {
                                            ...filteredData,
                                            ...Data,
                                          };
                                          if (filterOption) {
                                            mergedData.type = filterOption;
                                          }

                                          setFiltersData(mergedData);
                                          // getFilteredEvents(mergedData);
                                        }}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                          marginTop: "-8px",
                                        }}
                                      />
                                    </div>
                                  )}
                                {data.type === "dropdown" &&
                                  data.name === data1.name && (
                                    <div
                                      className="mt-2"
                                      style={{ maxWidth: "" }}
                                    >
                                      <Select
                                        name={data.name}
                                        className="w-100 selectpicker"
                                        aria-labelledby="dealership-name-quick"
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        value={(() => {
                                          const matchingFilter =
                                            checkedFields?.find(
                                              (field) =>
                                                field.name === data.name
                                            )?.value;

                                          // Ensure value is an array as it's a multi-select
                                          const selectedValues = Array.isArray(
                                            matchingFilter
                                          )
                                            ? matchingFilter
                                            : [];

                                          return (
                                            data.name === "dealership"
                                              ? dealerOptions
                                              : data.name === "project_manager"
                                              ? managers
                                              : data.name === "sales_rep"
                                              ? repOptions2
                                              : data.name === "package"
                                              ? packageOptions
                                              : (data?.name === "no_show_agent" || data?.name === "confirmation_agent") ? agentOptions :  animatorOptions
                                          )?.filter((option) =>
                                            selectedValues.includes(
                                              option.value
                                            )
                                          ) || [];
                                        })()}
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        options={
                                          data.name === "dealership"
                                            ? dealerOptions
                                            : data.name === "project_manager"
                                            ? managers
                                            : data.name === "sales_rep"
                                            ? repOptions2
                                            : data.name === "package"
                                            ? packageOptions
                                            : (data?.name === "no_show_agent" || data?.name === "confirmation_agent")  ? agentOptions : animatorOptions
                                        }
                                        maxMenuHeight={"120px"} // Set a max height for the dropdown menu
                                        onChange={(selectedOption) => {
                                          // ✅ Extract selected values from the dropdown
                                          const selectedIds =
                                            selectedOption.map(
                                              (item) => item.value
                                            );

                                          // ✅ Update checkedFields while preventing duplicates
                                          const updatedCheckedFields =
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    value: [
                                                      ...new Set(selectedIds),
                                                    ], // Remove duplicates
                                                  }
                                                : field
                                            );

                                          setCheckedFields(
                                            updatedCheckedFields
                                          );

                                          // ✅ Prepare the dropdown data object
                                          const Data = {
                                            [`${data.name}_id`]: [
                                              ...new Set(selectedIds),
                                            ], // Ensure unique values
                                          };

                                          // ✅ Ensure only non-empty fields are considered
                                          const filteredFields =
                                            updatedCheckedFields.filter(
                                              (field) =>
                                                field.value &&
                                                field.value
                                                  .toString()
                                                  .trim() !== ""
                                            );

                                          // ✅ Convert checkedFields to key-value pairs while preserving strings
                                          const filteredData =
                                            filteredFields.reduce(
                                              (acc, field) => {
                                                const key =
                                                  field.type === "dropdown"
                                                    ? `${field.name}_id`
                                                    : field.name;

                                                if (!acc[key]) acc[key] = [];

                                                // ✅ Preserve text values & filterType correctly
                                                if (field.type === "text") {
                                                  acc[key] = [
                                                    field.value,
                                                    field.filterType,
                                                  ]; // Keep both value & filterType
                                                } else if (
                                                  Array.isArray(field.value)
                                                ) {
                                                  acc[key] = [
                                                    ...new Set([
                                                      ...acc[key],
                                                      ...field.value,
                                                    ]),
                                                  ]; // Prevent duplicates
                                                } else {
                                                  if (
                                                    !acc[key].includes(
                                                      field.value
                                                    )
                                                  ) {
                                                    acc[key].push(field.value);
                                                  }
                                                }

                                                return acc;
                                              },
                                              {}
                                            );

                                          // ✅ Merge filtered data while preserving previous values
                                          const mergedData = {
                                            ...filtersData,
                                            ...filteredData,
                                            ...Data, // Ensure latest values overwrite old ones
                                          };

                                          if (filterOption) {
                                            mergedData.type = filterOption;
                                          }

                                          setFiltersData(mergedData);
                                          console.log(
                                            "Updated Filters Data:",
                                            mergedData
                                          );
                                        }}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            width: "100%",
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "white",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none",
                                            borderRadius: ".45rem",
                                            maxHeight: "38px", // Constrain the height of the control
                                            overflowY: "hidden", // Hide overflow
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem",
                                            width: "100%",
                                            overflow: "hidden", // Hide overflow for the container
                                          }),
                                          multiValue: (provided) => ({
                                            ...provided,
                                            maxWidth: "calc(100% - 40px)", // Adjust as needed
                                            overflow: "hidden",
                                            textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                          }),
                                          multiValueLabel: (provided) => ({
                                            ...provided,
                                            whiteSpace: "nowrap", // Prevent text from wrapping
                                          }),
                                          // multiValueRemove: (provided) => ({
                                          //   ...provided,
                                          //   cursor: 'pointer',
                                          // }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0",
                                            zIndex: "999",
                                            width: "100%",
                                            maxHeight: "120px", // Set a max height for the dropdown menu
                                            overflowY: "auto", // Add vertical scrollbar if content overflows
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333",
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  )}

                                {data.type === "date_range" &&
                                  data.name === data1.name && (
                                    <div className="mt-3">
                                      {data?.component?.map(
                                        (componentItem, compIndex) => (
                                          <div
                                            key={componentItem.name}
                                            className="d-flex flex-column"
                                          >
                                            <label>
                                              {componentItem.content}
                                            </label>
                                            <input
                                              type="date"
                                              name={componentItem.name}
                                              className={`my-2 form-control ${
                                                isDarkMode ? "custom-date" : ""
                                              }`}
                                              value={
                                                checkedFields?.find(
                                                  (field) =>
                                                    field.name === data.name
                                                )?.component[compIndex]?.value
                                              }
                                              onChange={(e) => {
                                                const selectedDate =
                                                  e.target.value;
                                                let startDate = "";
                                                let endDate = "";

                                                checkedFields.forEach(
                                                  (field) => {
                                                    if (
                                                      field.type ===
                                                        "date_range" &&
                                                      Array.isArray(
                                                        field.component
                                                      )
                                                    ) {
                                                      field.component.forEach(
                                                        (compItem) => {
                                                          if (
                                                            compItem.name ===
                                                            "start_date"
                                                          )
                                                            startDate =
                                                              compItem.value ||
                                                              "";
                                                          if (
                                                            compItem.name ===
                                                            "end_date"
                                                          )
                                                            endDate =
                                                              compItem.value ||
                                                              "";
                                                        }
                                                      );
                                                    }
                                                  }
                                                );

                                                if (
                                                  componentItem.name ===
                                                    "end_date" &&
                                                  !startDate
                                                ) {
                                                  toast.error(
                                                    translations.alertMessages
                                                      .selectStartDateFirst[
                                                      language
                                                    ]
                                                  );
                                                  e.target.value = "";
                                                  return;
                                                }

                                                if (
                                                  componentItem.name ===
                                                    "end_date" &&
                                                  selectedDate < startDate
                                                ) {
                                                  toast.error(
                                                    translations.alertMessages
                                                      .endDateNotBeforeStartDate[
                                                      language
                                                    ]
                                                  );
                                                  e.target.value = "";
                                                  return;
                                                }

                                                console.log(
                                                  "Valid date selected"
                                                );
                                                // ✅ Update checkedFields while preserving other values
                                                const updatedCheckedFields =
                                                  checkedFields.map((field) => {
                                                    if (
                                                      field.type ===
                                                        "date_range" &&
                                                      Array.isArray(
                                                        field.component
                                                      )
                                                    ) {
                                                      return {
                                                        ...field,
                                                        component:
                                                          field.component.map(
                                                            (compItem) =>
                                                              compItem.name ===
                                                              componentItem.name
                                                                ? {
                                                                    ...compItem,
                                                                    value:
                                                                      selectedDate,
                                                                  }
                                                                : compItem
                                                          ),
                                                      };
                                                    }
                                                    return field;
                                                  });

                                                setCheckedFields(
                                                  updatedCheckedFields
                                                );
                                                // ✅ Preserve existing values in filtersData while updating date fields
                                                const existingFilteredData = {
                                                  ...filtersData,
                                                };

                                                updatedCheckedFields.forEach(
                                                  (field) => {
                                                    if (
                                                      field.type ===
                                                        "date_range" &&
                                                      Array.isArray(
                                                        field.component
                                                      )
                                                    ) {
                                                      field.component.forEach(
                                                        (compItem) => {
                                                          if (
                                                            compItem.value &&
                                                            compItem.value.trim() !==
                                                              ""
                                                          ) {
                                                            existingFilteredData[
                                                              compItem.name
                                                            ] = compItem.value;
                                                          }
                                                        }
                                                      );
                                                    }
                                                  }
                                                );

                                                // Merge with the newly selected date
                                                const mergedData = {
                                                  ...existingFilteredData,
                                                  [componentItem.name]:
                                                    selectedDate,
                                                };

                                                if (filterOption) {
                                                  mergedData.type =
                                                    filterOption;
                                                }

                                                setFiltersData(mergedData);
                                              }}
                                              style={{
                                                backgroundColor: isDarkMode
                                                  ? "transparent"
                                                  : "",
                                                color: isDarkMode
                                                  ? "#ffffff"
                                                  : "#000000",
                                                border: isDarkMode
                                                  ? "1px solid #495057"
                                                  : "1px solid #dee2e6",
                                                marginTop: "-8px",
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </div>
                            ))}
                        </>
                      )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div
          className="d-flex align-items-center justify-content-between mx-4 pt-3"
          style={{
            borderTop: "1px solid #dee2e6",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-end"
            style={
              {
                // borderTop: "1px solid #dee2e6",
              }
            }
          >
            {checkedFields && checkedFields.length > 0 && (
              <button
                className="d-flex btn btn-light align-items-center me-4 px-4 py-2"
                type="button"
                // disabled={
                //   checkedFields &&
                //   checkedFields.length > 0 &&
                //   checkedFields.some(
                //     (field) => field?.value === "" || field?.value?.length === 0
                //   )
                // }
                onClick={() => {
                  getFilteredEvents([], []);
                  const clearAll = [];
                  addFilter(clearAll);
                  setCheckedFields([]);
                  // localStorage.setItem("eventsFilterData", JSON.stringify([]));
                  setFiltersData([]);
                  setWeekRange({ range1: "", range2: "" });
                  button.click();
                }}
                style={{ background: "", border: "none" }}
              >
                <i className="bi bi-x me-2"></i>
                {translations.commonWords.clearAll[language]}{" "}
              </button>
            )}
          </div>

          <button
            className="d-flex btn btn-primary align-items-center text-black admin-btn px-5 py-2"
            type="button"
            disabled={
              checkedFields.length === 0 || // Disable if checkedFields is empty
              checkedFields.some((field) => {
                if (field.type === "date_range") {
                  return field.component.some(
                    (comp) =>
                      comp.value === "" ||
                      (Array.isArray(comp.value) && comp.value.length === 0)
                  );
                }
                return (
                  field?.value === "" ||
                  (Array.isArray(field?.value) && field.value.length === 0)
                );
              })
            }
            onClick={() => {
              // if (
              //   checkedFields.filter(
              //     (field) => field?.value === "" || field?.value.length === 0
              //   )
              // ) {
              //   toast.error("Set Filters First");
              // }
              getFilteredEvents(filtersData, checkedFields);
              button.click();
            }}
          >
            {translations.commonWords.search[language]}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
