import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import BulkUpload from "./bulkuploadModal";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import Loader from "../loader";
import ClientsFilterbar from "./clientsFilterbar";
import { internalUsers } from "../constants/userTypes";
import { format } from "date-fns";
import * as XLSX from "xlsx";

const Clients = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  seListId,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const userType = localStorage.getItem("userType");
  const clientsFilterData = JSON.parse(
    localStorage.getItem("clientsFilterData")
  );
  const clientsFilterPostData = JSON.parse(
    localStorage.getItem("clientsFilterPostData")
  );
  const [deleteStates, setDeleteStates] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [listIds, setListIds] = useState([]);
  const [configIds, setConfigIds] = useState([]);
  const [checkedFields, setCheckedFields] = useState(clientsFilterData || []);
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState(clientsFilterPostData || []);
  const [dealershipDetail, setDealershipDetail] = useState("");
  const [callStatusOptions, setCallStatusOptions] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [prospectiveType, setProspectiveType] = useState("");

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.email[language],
      name: "email",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.make[language],
      name: "make",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.model[language],
      name: "model",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.usersPage.createUser.year[language],
      name: "year",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: "KM",
      name: "km",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.clients.table.callStatus[language],
      name: "call_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.address[language],
      name: "address",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.city[language],
      name: "city",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.province[language],
      name: "province",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "Activix ID",
      name: "activix_id",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.prospectiveType[language],
      name: "prospective_type",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.forms.formFields.conquestSection.postalCode[language],
      name: "postal_code",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.appointments.appointment[language],
      name: "appointment",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.commonFields.language[language],
      name: "language",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.VIN[language],
      name: "vin",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.purchaseDate[language],
      name: "purchase_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.lastServiceDate[language],
      name: "last_service_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospectiveSource[language],
      name: "prospect_source",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.greetings[language],
      name: "greetings",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "OG Rep",
      name: "og_rep",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];
  const prospectiveSourceOptions2 = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt3[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    // },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt4[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    // },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt8[language],
      value: translations.createProspective.prospectiveSourceOptions.opt8["en"],
    },
  ];

  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));
  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "WANTCB",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "MSG LEFT",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    if (token && eventId) {
      fetchData();
      getExternalUsers();
      // getListIds();
    }
    // getFiltersData();
  }, [eventId]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + `/api/user/prospective-index/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          clientsFilterData && clientsFilterData?.length > 0
            ? {
                ...clientsFilterPostData,
                private_event_id: eventId,
                all_prospects: false,
              }
            : {
                private_event_id: eventId,
                all_prospects: false,
              }
        ),
      });
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;
        const callOptions =
          data.prospectives && data.prospectives.length > 0
            ? Array.from(
                new Set(
                  data.prospectives
                    .filter((item) => item?.call_status != null) // Filter out null or undefined call_status
                    .map((item) => item.call_status.toString()) // Map to call_status strings
                )
              ).map((call_status) => ({
                label: call_status,
                value: call_status,
              }))
            : [];

        setClients(data.prospectives);
        setCallStatusOptions(callOptions);
        seListId({ list_id: data.liste_id, list_name: data.list_name });
        setDealershipDetail(data?.dealershipDetails);
        setDeleteStates(data.prospectives.map(() => false));

        // autoScrollMid();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setLoading(false);
    }
  };

  const getExternalUsers = async () => {
    // setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        const typeOptions = data?.prospective_type?.length
          ? data?.prospective_type?.map((list) => ({
              label: list,
              value: list,
            }))
          : [];

        setProspectiveType(typeOptions);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setIsLoading(false);
    }
  };
  const getListIds = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/list-ids/" + eventId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        const configs =
          data?.config_ids?.lists &&
          data?.config_ids?.lists.length > 0 &&
          data?.config_ids?.lists.map((list) => {
            return {
              label: list?.calendar_config_name,
              value: list?.calendar_config_id,
            };
          });
        const lists =
          data?.list_ids?.lists &&
          data?.list_ids?.lists.length > 0 &&
          data?.list_ids?.lists.map((list) => {
            return { label: list?.list_name, value: list?.list_id };
          });

        setListIds(lists);
        setConfigIds(configs);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/prospectives/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success("Dealership deleted successful");
        const newData = clients.filter((client) => client.id !== id);
        setClients(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleCheckboxChange = (clientId) => {
    setSelectedClients(
      (prevSelected) =>
        prevSelected.includes(clientId)
          ? prevSelected.filter((id) => id !== clientId) // Deselect if selected
          : [...prevSelected, clientId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedClients([]);
    } else {
      // Select all appointments' IDs
      const allClientIds = clients.map((client) => client.id);
      setSelectedClients(allClientIds);
    }
    setSelectAll(!selectAll); // Toggle the state of selectAll
  };
  const exportToExcel = () => {
    // Filter the selected appointments based on their IDs
    const selectedData = clients
      .filter((client) => selectedClients.includes(client.id)) // Only include selected appointments
      .map((client) => ({
        Event_ID: client?.private_event?.nom_vp || "",
        Prospect_Type: client?.prospect_type || "",
        Prospect_Source: client?.prospect_source || "",
        First_Name: client?.first_name || "",
        Last_Name: client?.last_name || "",
        Phone: client?.phone || "",
        Office_Number: client?.office_phone || "",
        Representative: client?.og_rep || "",
        Call_Status: client?.call_status || "",
        Make: client?.make || "",
        Model: client?.model || "",
        Year: client?.year || "",
        Confirmation_Status: client?.appointments[0]?.confirmation_status || "",
        Confirmation_Notes: client?.appointments[0]?.confirmation_notes || "",
        Visit_Status: client?.appointments[0]?.visit_status || "",
        Visit_Notes: client?.appointments[0]?.visit_notes || "",
        No_Show_Status: client?.appointments[0]?.no_show_status || "",
        No_Show_Notes: client?.appointments[0]?.no_show_notes || "",

        appointment_time: client?.appointments[0]?.appointment_time || "",
        appointment_date: client?.appointments[0]?.appointment_date || "",
        appointment_comment: client?.appointments[0]?.appointment_comment || "",
        appointment_rep_user_name:
          client?.appointments[0]?.appointment_rep_user_name || "",
        Province: client?.province || "",
        Purchase_Date: client?.purchase_date || "",
        VIN: client?.vin || "",
        Visit_Status_User: client?.visit_status_user || "",
        Email: client?.email || "",
        Greetings: client?.greetings || "",
        Client_ID: client?.id || "",
        KM: client?.km || "",
        Language: client?.language || "",
        List_ID: client?.list_id || "",
        Mobile_Phone: client?.mobile_phone || "",
        Postal_Code: client?.postal_code || "",
        Address: client?.address || "",
        Appointment: client?.appointment || "",
        City: client?.city || "",
      }));

    // Generate the Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Clients");

    // Save the file
    XLSX.writeFile(
      workbook,
      `Clients Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };

  const getFilteredEvents = async (filterPost, checkedFieldsData) => {
    // setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/prospective-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          private_event_id: eventId,
          ...filterPost,
          all_prospects: false,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem(
          "clientsFilterPostData",
          JSON.stringify(filterPost)
        );
        localStorage.setItem(
          "clientsFilterData",
          JSON.stringify(checkedFieldsData)
        );
        setClients(data.prospectives);
        setDeleteStates(data.prospectives.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  const getTranslatedConfirmationStatus = (status) => {
    const option = confirmationStatusOptions.find(
      (opt) => opt.value === status
    );
    return option ? option.label : status;
  };
  const getTranslatedVisitStatus = (status) => {
    const option = visitStatusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getTranslatedNoShowStatus = (status) => {
    const option = noShowOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <BulkUpload
              isDarkMode={isDarkMode}
              eventId={eventId}
              listIds={listIds}
              configIds={configIds}
              getListIds={getListIds}
              fetchData={fetchData}
            />
            <ClientsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              textOptions2={textOptions2}
              appointmentOption={appointmentOption}
              prospectiveSourceOptions={prospectiveSourceOptions2}
              callStatusOptions={callStatusOptions}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              prospectiveType={prospectiveType}
              // weekOptions={weekOptions}
            />
            <div className="row align-items-center justify-content-between pb-2">
              <div className="col-7 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.clients.title[language]} {" - "}{" "}
                    {dealershipDetail?.name}
                    {": "}
                    {dealershipDetail?.start_date}{" "}
                    {translations.commonWords.to[language]}{" "}
                    {dealershipDetail?.end_date}
                  </h2>
                </div>
              </div>
              <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("event-view");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=event-view&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("appointments");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=appointments&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className=""
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    style={{
                      background: "#05cc9e",
                      // color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("copy-client");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=copy-client&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-clipboard-plus"></i>{" "}
                    {translations.forms.formFields.copyClient[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("eventReport");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=eventReport&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div
                  className="row align-items-center"
                  // style={{ minHeight: "44px" }}
                >
                  <div className="col-3">
                    {(userData.roles[0].name === "Super Admin" ||
                      userData.roles[0].name === "Admin") && (
                      <>
                        {selectedClients && selectedClients.length > 0 && (
                          <button
                            type="button"
                            className="client-btn d-flex align-items-center gap-2"
                            onClick={exportToExcel}
                          >
                            <i className="bi bi-upload"></i>
                            {translations.commonWords.export[language]}{" "}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("create-prospective");
                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=create-prospective&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className={`bi bi-clipboard-plus`}></i>{" "}
                    {translations.commonFields.addBtn[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {userType &&
                    internalUsers.includes(userType) &&
                    userType !== "Animator" && (
                      <button
                        type="button"
                        className="btn admin-btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#bulkUploadModal"
                        id="add-dlr-trigger"
                      >
                        <i className="bi bi-upload"></i>{" "}
                        {translations.commonFields.bulkUpload[language]}
                      </button>
                    )}
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    id=""
                    type="button"
                    className="btn admin-btn btn-primary"
                    data-bs-target="#clientFiltersidebar"
                    data-bs-toggle="offcanvas"
                    aria-bs-controls="offcanvasmain"
                  >
                    <i className="bi bi-search"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="headersec row">
              <div className="my-3 col-12">
                <div className="d-flex flex-wrap gap-3 align-items-center">
                  {clientsFilterData &&
                    clientsFilterData?.length > 0 &&
                    clientsFilterData?.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex py-1 px-2 gap-2 align-items-center justify-content-between"
                        style={{
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                          borderRadius: "0.375rem",
                          minWidth: "100px",
                        }}
                      >
                        <p className="text-truncate">{item?.content}</p>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let updatedCheckedFields = checkedFields.filter(
                              (field) => field.name !== item.name
                            );

                            // Remove the object with the matching name
                            setCheckedFields(updatedCheckedFields);

                            const keysToRemove = [
                              item.name,
                            ];
                            const updatedFiltersData = Object.fromEntries(
                              Object.entries(filtersData).filter(
                                ([key]) => !keysToRemove.includes(key)
                              )
                            );

                            setFiltersData(updatedFiltersData);

                            getFilteredEvents(
                              updatedFiltersData,
                              updatedCheckedFields
                            );
                          }}
                        >
                          <i
                            className="bi bi-x-circle-fill"
                            style={{
                              color: isDarkMode ? "#495057" : "#868686",
                            }}
                          >
                            {" "}
                          </i>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              // className="pt-4"
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-between",
              // }}
              // ref={containerRef}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
                justifyContent: "space-between",
                height: "calc(100vh - 260px)",
              }}
            >
              <table className="table table-hover text-center align-middle">
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    {userData &&
                      (userData.roles[0].name === "Super Admin" ||
                        userData.roles[0].name === "Admin") && (
                        <th
                          scope="col"
                          className={`${isDarkMode ? "darkthead" : ""}`}
                        >
                          {
                            <input
                              type="checkbox"
                              className=""
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          }
                        </th>
                      )}
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "125px" : "150px",
                      }}
                    >
                      {translations.clients.table.prospectiveType[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "140px" : "165px",
                      }}
                    >
                      {
                        translations.createProspective.prospectiveSource[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.commonFields.firstName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "135px",
                      }}
                    >
                      {translations.commonFields.lastName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "180px",
                      }}
                    >
                      {translations.quickCreationModal.phoneNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "155px",
                      }}
                    >
                      {translations.createProspective.officeNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "150px" : "150px",
                        maxWidth: language === "en" ? "150px" : "150px",
                      }}
                    >
                      {
                        translations.commonWords.usersTypes.representative[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "150px" : "150px",
                      }}
                    >
                      {translations.clients.table.callStatus[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.make[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.model[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{ minWidth: language === "en" ? "95px" : "90px" }}
                    >
                      {translations.usersPage.createUser.year[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "170px" : "195px",
                      }}
                    >
                      {
                        translations.createProspective.confirmationStatus[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "165px" : "195px",
                      }}
                    >
                      {
                        translations.createProspective.confirmationNotes[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {translations.createProspective.visitStatus[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {translations.createProspective.visitNotes[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "170px" : "195px",
                      }}
                    >
                      {translations.clients.table.noShowStatus[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {translations.createProspective.noShowNotes[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {translations.clients.table.appointmentTime[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {translations.clients.table.appointmentDate[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      {
                        translations.createProspective.appointmentComment[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "220px" : "255px",
                      }}
                    >
                      Appointment User Name
                    </th>

                    <th
                      scope="col"
                      colSpan={2}
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        right: 0,
                      }}
                    >
                      Actions
                    </th>
                    {/* <th scope="col"></th> */}
                  </tr>
                </thead>
                {clients && !loading && (
                  <tbody id="">
                    {clients && clients.length > 0 ? (
                      clients.map((client, index) => {
                        return (
                          <>
                            <tr className="result-box" key={client?.id}>
                              {userData &&
                                (userData.roles[0].name === "Super Admin" ||
                                  userData.roles[0].name === "Admin") && (
                                  <td
                                    className="fw-bold"
                                    style={
                                      {
                                        // backgroundColor: "#ced4da66",
                                        // borderTop: "1px solid #dee2e6",
                                        // borderBottom: "1px solid #dee2e6",
                                      }
                                    }
                                  >
                                    {
                                      <input
                                        type="checkbox"
                                        className=""
                                        checked={selectedClients.includes(
                                          client.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(client.id)
                                        }
                                      />
                                    }
                                  </td>
                                )}
                              <th className="custID" scope="row">
                                {client?.id}
                              </th>
                              <td className="dlrname">
                                {client?.prospect_type}
                              </td>
                              <td className="dlrname">
                                {client?.prospect_source
                                  ? (() => {
                                      const matchedOption =
                                        prospectiveSourceOptions.find(
                                          (option) =>
                                            option.value.en?.toLowerCase() ===
                                              client?.prospect_source?.toLowerCase() ||
                                            option.value.fr?.toLowerCase() ===
                                              client?.prospect_source?.toLowerCase()
                                        );

                                      // Return the label in the current language if a match is found
                                      return matchedOption
                                        ? matchedOption.label[language]
                                        : client?.prospect_source;
                                    })()
                                  : ""}
                              </td>
                              <td className="dlrname">
                                {client?.first_name === "null"
                                  ? ""
                                  : client?.first_name}
                              </td>
                              <td className="dlrname">
                                {client?.last_name === "null"
                                  ? ""
                                  : client?.last_name}
                              </td>
                              <td className="salesrep">{client?.phone}</td>
                              <td className="salesrep">
                                {client?.office_phone}
                              </td>

                              <td
                                className="salesrep"
                                style={{
                                  minWidth:
                                    language === "en" ? "150px" : "150px",
                                  maxWidth:
                                    language === "en" ? "150px" : "150px",
                                  overflow: "hidden", // Hide content that exceeds the boundaries
                                  textOverflow: "ellipsis", // Display ellipsis (...) for overflowed text
                                  whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                                }}
                              >
                                {client?.og_rep}
                              </td>
                              <td className="salesrep">
                                {client?.call_status}
                              </td>
                              <td className="salesrep">{client?.make}</td>
                              <td className="salesrep">{client?.model}</td>
                              <td className="salesrep">{client?.year}</td>
                              <td
                                className="salesrep"
                                style={{ cursor: "pointer" }}
                              >
                                {getTranslatedConfirmationStatus(
                                  client?.appointments[0]?.confirmation_status
                                )}
                              </td>

                              <td
                                className="salesrep"
                                style={{ cursor: "pointer" }}
                              >
                                {client?.appointments[0]?.confirmation_notes}
                              </td>
                              <td className="salesrep">
                                {getTranslatedVisitStatus(
                                  client?.appointments[0]?.visit_status
                                )}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.visit_notes}
                              </td>
                              <td className="salesrep">
                                {getTranslatedNoShowStatus(
                                  client?.appointments[0]?.no_show_status
                                )}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.no_show_notes}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.appointment_time}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.appointment_date}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.appointment_comment}
                              </td>
                              <td className="salesrep">
                                {client?.appointments[0]?.appointment_comment}
                              </td>
                              {/* <td className="salesrep">
                              {client?.no_show_status}
                            </td> */}
                              <td
                                style={{
                                  position: "-webkit-sticky",
                                  position: "sticky",
                                  right:
                                    userData &&
                                    userData.roles[0].permissions.some(
                                      (item) =>
                                        item.name === "Prospective Destroy"
                                    )
                                      ? language == "fr"
                                        ? "136px"
                                        : "110px"
                                      : 0,
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                  //   data-customer-id="405"
                                  //   data-bs-toggle="modal"
                                  //   data-bs-target="#AddDealerModal"
                                  onClick={() => {
                                    setDisplayComponent("edit-prospective");
                                    //   setEditEventID(event.id);
                                    window.history.pushState(
                                      {
                                        id: "gallery",
                                        randomData: window.Math.random(),
                                      },
                                      "title",
                                      "/event-portal?page=edit-prospective&event_id=" +
                                        eventId +
                                        `&client_id=${client?.id}`
                                    );
                                  }}
                                >
                                  <i className="bi bi-eye"></i>{" "}
                                  {translations.commonWords.view[language]}
                                </button>
                              </td>
                              {userData &&
                                userData.roles[0].permissions.some(
                                  (item) => item.name === "Prospective Destroy"
                                ) && (
                                  <td
                                    style={{
                                      width: "170px",
                                      position: "-webkit-sticky",
                                      position: "sticky",
                                      right: 0,
                                    }}
                                  >
                                    <div className="btn-group" role="group">
                                      {deleteStates[index] ? (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-success delete-button"
                                            data-customer-id="405"
                                            onClick={() =>
                                              handleDelete(client?.id, index)
                                            }
                                          >
                                            {
                                              translations.commonFields.confirm[
                                                language
                                              ]
                                            }
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary cancel-button"
                                            onClick={() =>
                                              setDeleteStates((prevStates) => {
                                                const newStates = [
                                                  ...prevStates,
                                                ];
                                                newStates[index] = false;
                                                return newStates;
                                              })
                                            }
                                          >
                                            {
                                              translations.commonFields.cancel[
                                                language
                                              ]
                                            }
                                          </button>
                                        </>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                          dataset-button-state="delete"
                                          onClick={() =>
                                            setDeleteStates((prevStates) => {
                                              const newStates = [...prevStates];
                                              newStates[index] = true;
                                              return newStates;
                                            })
                                          }
                                        >
                                          <i className="bi bi-trash"></i>{" "}
                                          {
                                            translations.commonFields.delete[
                                              language
                                            ]
                                          }
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                )}
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr
                        className="align-middle"
                        style={{
                          position: "relative",
                          // height: "30px",
                          // zIndex: 10 + eventList.length - index,
                        }}
                      >
                        <td
                          style={{
                            background: `${
                              isDarkMode ? "transparent" : "white"
                            }`,
                            borderStyle: "none",
                          }}
                          colSpan={14}
                          className="text-center py-5"
                        >
                          <h4>
                            {translations.EventsPortal.noDataMessage[language]}
                          </h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Clients;
